import Alpine from "alpinejs";

if (document.querySelector(".js-copy")) {
  import("./copy").then(init => init.default());
}

declare global {
  interface Window {
    Alpine;
  }
}

window.Alpine = Alpine;

const limeWoodFontStack = "sans-serif";
const limeWoodPrimaryColor = "#c4d600";
const limeWoodSecondaryColor = "#292f2c";
const limeWoodTertiaryColor = "#f9fae2";
const limeWoodTheme1Foreground = "#c4d600";
const limeWoodTheme1Background = "#293436";
const limeWoodTheme2Foreground = "#ffffff";
const limeWoodTheme2Background = "#293436";
const limeWoodAddress = "Beaulieu Road, Lyndhurst<br>Hampshire, SO43 7FZ";
const limeWoodFacebookUrl = "https://www.facebook.com/LimeWoodHotel/";
const limeWoodFacebookIcon = "img/socials/facebook-c4d600.png";
const limeWoodXUrl = "https://x.com/limewoodhotel/";
const limeWoodXIcon = "img/socials/x-c4d600.png";
const limeWoodInstagramUrl = "https://www.instagram.com/limewoodhotel/";
const limeWoodInstagramIcon = "img/socials/instagram-c4d600.png";
const limeWoodYouTubeIcon = "img/socials/youtube-c4d600.png";
const personalFacebookIcon = "img/socials/facebook-000000.png";
const personalInstagramIcon = "img/socials/instagram-000000.png";
const personalXIcon = "img/socials/x-000000.png";

const defaultBrand = {
  fontStack: limeWoodFontStack,
  parent: "lime-wood-group",
  id: "lime-wood-group",
  name: "Lime Wood Group",
  url: "https://www.limewoodhotel.co.uk",
  cleanUrl: "www.limewoodhotel.co.uk",
  address:
    "Lime Wood Group Ltd<br>Hawkslease, Chapel Lane<br>Lyndhurst<br>SO43 7FG",
  logo: "img/brands/limewood-portetta.png",
  customLogo: "img/custom/fifty-best-uk-spas-2024.png",
  primaryColor: limeWoodPrimaryColor,
  secondaryColor: limeWoodSecondaryColor,
  tertiaryColor: limeWoodTertiaryColor,
  theme1Foreground: limeWoodTheme1Foreground,
  theme1Background: limeWoodTheme1Background,
  theme2Foreground: limeWoodTheme2Foreground,
  theme2Background: limeWoodTheme2Background,
  theme3Foreground: "#ffffff",
  theme3Background: "#4f868e",
  theme4Foreground: "#ffffff",
  theme4Background: "#ef3340",
  theme5Foreground: "#ffffff",
  theme5Background: "#a0b5cf",
  facebookUrl: limeWoodFacebookUrl,
  facebookIcon: limeWoodFacebookIcon,
  xUrl: limeWoodXUrl,
  xIcon: limeWoodXIcon,
  instagramUrl: limeWoodInstagramUrl,
  instagramIcon: limeWoodInstagramIcon,
  youTubeIcon: limeWoodYouTubeIcon,
  personalFacebookIcon,
  personalInstagramIcon,
  personalXIcon,
};

const allBrands = [
  defaultBrand,
  {
    fontStack: limeWoodFontStack,
    parent: "lime-wood-portetta-hhco",
    id: "lime-wood-portetta-hhco",
    name: "Lime Wood / Portetta / HH&Co",
    url: "https://www.limewoodhotel.co.uk",
    cleanUrl: "www.limewoodhotel.co.uk",
    logo: "img/brands/limewood-hhco.png",
    brandLogo: "img/brands/limewood-hhco-portetta-brand.png",
    primaryColor: limeWoodPrimaryColor,
    secondaryColor: limeWoodSecondaryColor,
    tertiaryColor: limeWoodTertiaryColor,
    theme1Foreground: limeWoodTheme1Foreground,
    theme1Background: limeWoodTheme1Background,
    theme2Foreground: limeWoodTheme2Foreground,
    theme2Background: limeWoodTheme2Background,
    facebookUrl: limeWoodFacebookUrl,
    facebookIcon: limeWoodFacebookIcon,
    xUrl: limeWoodXUrl,
    xIcon: limeWoodXIcon,
    instagramUrl: limeWoodInstagramUrl,
    instagramIcon: limeWoodInstagramIcon,
    youTubeIcon: limeWoodYouTubeIcon,
    personalFacebookIcon,
    personalInstagramIcon,
    personalXIcon,
    secondaryBrandFontStack: "sans-serif",
    secondaryBrandName: "Portetta",
    secondaryBrandUrl: "https://www.portetta.com",
    secondaryBrandCleanUrl: "www.portetta.com",
    secondaryBrandLogo: "img/brands/portetta.png",
    secondaryBrandColor: "#a0b5cf",
    secondaryBrandFacebookUrl: "https://www.facebook.com/pages/Portetta/290030524360027",
    secondaryBrandFacebookIcon: "img/socials/facebook-a0b5cf.png",
    secondaryBrandXUrl: "https://x.com/portetta",
    secondaryBrandXIcon: "img/socials/x-a0b5cf.png",
    secondaryBrandInstagramUrl: "https://www.instagram.com/portettahotel/",
    secondaryBrandInstagramIcon: "img/socials/instagram-a0b5cf.png",
  },
  {
    fontStack: limeWoodFontStack,
    parent: "lime-wood-group",
    id: "lime-wood",
    name: "Lime Wood",
    url: "https://www.limewoodhotel.co.uk",
    cleanUrl: "www.limewoodhotel.co.uk",
    address: limeWoodAddress,
    logo: "img/brands/limewood.png",
    customLogo: "img/custom/fifty-best-uk-spas-2024.png",
    primaryColor: limeWoodPrimaryColor,
    secondaryColor: limeWoodSecondaryColor,
    tertiaryColor: limeWoodTertiaryColor,
    theme1Foreground: limeWoodTheme1Foreground,
    theme1Background: limeWoodTheme1Background,
    theme2Foreground: limeWoodTheme2Foreground,
    theme2Background: limeWoodTheme2Background,
    facebookUrl: limeWoodFacebookUrl,
    facebookIcon: limeWoodFacebookIcon,
    xUrl: limeWoodXUrl,
    xIcon: limeWoodXIcon,
    instagramUrl: limeWoodInstagramUrl,
    instagramIcon: limeWoodInstagramIcon,
    youTubeIcon: limeWoodYouTubeIcon,
    personalFacebookIcon,
    personalInstagramIcon,
    personalXIcon,
    customSupporter: "img/custom/mhfa-england-instructor-member.png",
  },
  {
    fontStack: "sans-serif",
    parent: "herb-house",
    id: "herb-house",
    name: "Herb House",
    url: "https://herbhousespa.co.uk",
    cleanUrl: "herbhousespa.co.uk",
    address: limeWoodAddress,
    logo: "img/brands/herb-house.png",
    customLogo: "img/custom/fifty-best-uk-spas-2024.png",
    primaryColor: "#64ccc9",
    secondaryColor: "#4f868e",
    tertiaryColor: "#4f868e",
    theme1Foreground: "#ffffff",
    theme1Background: "#4f868e",
    theme2Foreground: "#64ccc9",
    theme2Background: "#293436",
    facebookUrl: "https://www.facebook.com/LimeWoodHotel/",
    facebookIcon: "img/socials/facebook-64ccc9.png",
    xUrl: "https://x.com/limewoodhotel/",
    xIcon: "img/socials/x-64ccc9.png",
    instagramUrl: "https://www.instagram.com/limewoodhotel/",
    instagramIcon: "img/socials/instagram-64ccc9.png",
    youTubeIcon: "img/socials/youtube-64ccc9.png",
    personalFacebookIcon,
    personalInstagramIcon,
    personalXIcon,
  },
  {
    fontStack: "sans-serif",
    parent: "hartnett-holder-co",
    id: "hartnett-holder-co",
    name: "Hartnett Holder & Co",
    url: "https://hartnettholderandco.com",
    cleanUrl: "hartnettholderandco.com",
    address: limeWoodAddress,
    logo: "img/brands/hhco.png",
    brandLogo: "img/brands/hhco-brand.png",
    primaryColor: "#ef3340",
    secondaryColor: "#9a9a9a",
    tertiaryColor: "#9a9a9a",
    theme1Foreground: "#ffffff",
    theme1Background: "#ef3340",
    theme2Foreground: "#ffffff",
    theme2Background: "#293436",
    facebookUrl: "https://www.facebook.com/LimeWoodHotel/",
    facebookIcon: "img/socials/facebook-ef3340.png",
    xUrl: "https://x.com/limewoodhotel/",
    xIcon: "img/socials/x-ef3340.png",
    instagramUrl: "https://www.instagram.com/limewoodhotel/",
    instagramIcon: "img/socials/instagram-ef3340.png",
    youTubeIcon: "img/socials/youtube-ef3340.png",
    personalFacebookIcon,
    personalInstagramIcon,
    personalXIcon,
  },
  {
    fontStack: "sans-serif",
    parent: "portetta",
    id: "portetta",
    name: "Portetta",
    url: "https://www.portetta.com",
    cleanUrl: "www.portetta.com",
    address: "Courchevel Moriond (1650)<br>73120 Saint Bon<br>France",
    logo: "img/brands/portetta.png",
    primaryColor: "#a0b5cf",
    secondaryColor: "#0e2b32",
    tertiaryColor: "#0e2b32",
    theme1Foreground: "#ffffff",
    theme1Background: "#a0b5cf",
    theme2Foreground: "#0e2b32",
    theme2Background: "#ffffff",
    facebookUrl: "https://www.facebook.com/pages/Portetta/290030524360027",
    facebookIcon: "img/socials/facebook-a0b5cf.png",
    xUrl: "https://x.com/portetta",
    xIcon: "img/socials/x-a0b5cf.png",
    instagramUrl: "https://www.instagram.com/portettahotel/",
    instagramIcon: "img/socials/instagram-a0b5cf.png",
    youTubeIcon: "img/socials/youtube-a0b5cf.png",
    personalFacebookIcon,
    personalInstagramIcon,
    personalXIcon,
  },
];

Alpine.store("brands", {
  current: {
    defaultBrand,
  },
  items: allBrands,
});

Alpine.start();
